<template>
    <div class="container container--main">
        <PageNavigation />
        <slot></slot>
        <DelayHydration>
            <LazyPageFooter />
        </DelayHydration>
    </div>
    <ClientOnly>
        <DelayHydration>
            <LazyGlobalAlert />
            <LazySearchMask />
            <LazyModalShoppingCart />
            <LazyProductShoppingCart
                :message="true"
                :quantity="basketStore.cartModalVal('common', 'quantity')"
                :price-info="basketStore.cartModalVal('common', 'price')"
                :payment="true"
                id="common"
            />
        </DelayHydration>
    </ClientOnly>
</template>

<script setup>
import { useBasketStore } from '~/stores/basket';

const basketStore = useBasketStore();
</script>

<style lang="scss" scoped></style>
